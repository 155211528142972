/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { MDXProvider } from "@mdx-js/react";

import PageLayout from "./PageLayout";
import BackLink from "./BackLink";
import PageImage from "./PageImage";
import ThemedParagraph from "./ThemedParagraph";
import useSiteMetadata from "../hooks/use-sitemetadata";
import PageTransition from "./PageTransition";
import PageLink from "./PageLink";

const components = {
  h1: Themed.h1,
  h2: Themed.h2,
  h3: Themed.h3,
  h4: Themed.h4,
  h5: Themed.h5,
  table: Themed.table,
  tr: Themed.tr,
  th: Themed.th,
  ul: Themed.ul,
  p: Themed.p,
  ThemedParagraph,
  Image: PageImage,
  a: PageLink
};

const MarkdownPagesLayout = ({ children }) => {
  const { basePath } = useSiteMetadata();
  return (
    <MDXProvider components={components}>
      <PageLayout>
        <PageTransition>
          <BackLink path={basePath}>Back to recipes</BackLink>
          <div sx={{ variant: "markdownWrappers.pages" }}>{children}</div>
        </PageTransition>
      </PageLayout>
    </MDXProvider>
  );
};

export default MarkdownPagesLayout;
