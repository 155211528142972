/** @jsx jsx */
import { jsx, Themed } from "theme-ui";

const ThemedParagraph = props => (
  <Themed.p
    sx={{
      width: "100%",
      maxWidth: "none !important",
      variant: "text.medium"
    }}
    {...props}
  />
);
export default ThemedParagraph;
