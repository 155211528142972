/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import ThemedLink from "../components/ThemedLink";

const BackLink = ({ path = "/", children }) => {
  return (
    <ThemedLink
      to={path}
      size="small"
      css={css`
        align-self: flex-start;
        @media print {
          display: none;
        } 
      `}
    >
      <span aria-hidden>{"<"} </span> <span>{children}</span>
    </ThemedLink>
  );
};

export default BackLink;
