/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import ThemedLink from "./ThemedLink";

const PageLink = ({ children, ...props }) => {
  return (
    <ThemedLink
      as="a"
      css={css`
        display: inline;
        text-decoration: underline;
        word-break: break-word;
        &::before {
          display: none;
        }
      `}
      {...props}
    >
      {children}
    </ThemedLink>
  );
};

export default PageLink;
